import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';

import { ResponsiveImage } from './index';
import { variables } from '../../stylesheets/variables';
import { mediaMin } from '../../stylesheets/MediaQueries';

const HeadingContainer = styled.div`
  height: ${props => {
    if (props.scrollProgress > props.windowHeight * 0.9) {
      return `${props.windowHeight * 0.1}px`;
    } else {
      return `${props.windowHeight - props.scrollProgress}px`;
    }
  }};
  width: 100%;
  color: #fff;
  overflow: hidden;
  z-index: 100;
  position: fixed;
  top: 0;
  ${mediaMin.tabletLandscape`
    height: ${props => {
      if (props.scrollProgress > props.windowHeight * 0.64) {
        return `${props.windowHeight * 0.06}px`;
      } else {
        return `${props.windowHeight * 0.7 - props.scrollProgress}px`;
      }
    }};
`}
  .responsive-image-container {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    .blue-heading-bg {
      margin-left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      min-width: 100%;
      min-height: 100%;
      max-width: unset !important;
      max-height: unset !important;
      ${mediaMin.tabletLandscape`
        min-height: ${props => props.windowHeight * 0.7}px;
      `}
    }
  }
`;

const OpacityLayer = styled.div`
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${variables.colors.darkBlue};
  opacity: ${props => props.scrollProgress / props.windowHeight};
  ${mediaMin.tabletLandscape`
    background-color: ${variables.colors.darkBlue};
    opacity: ${props => props.scrollProgress / (props.windowHeight * 0.64)};
  `}
`;

const LeftColumn = styled.div`
  z-index: 100;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${props => props.windowHeight}px;
  opacity: ${props => 1 - props.scrollProgress / (props.windowHeight * 0.5)};
  width: 100%;
  padding: 72px 36px;
  ${mediaMin.tablet`
    width: 50%;
  `}
  ${mediaMin.tabletLandscape`
    padding: 80px 48px;
    width: auto;
    height: ${props => props.windowHeight * 0.7}px;
    opacity: ${props => 1 - props.scrollProgress / (props.windowHeight * 0.32)};
`}
  .scroll-down {
    position: absolute;
    bottom: 80px;
    right: 77.25px;
    height: 20px;
    transform: ${props =>
      props.bounced ? 'translateY(-10px)' : 'translateY(0)'};
    transition: transform 0.5s ease;
    ${mediaMin.tabletLandscape`
      display: none;
    `};
  }
  .bottom,
  .top {
    display: flex;
    flex-direction: column;
    font-family: 'BrownStd Bold';
    a {
      z-index: 100;
      img {
        width: 100%;
      }
    }
    .page-title {
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 18px;
      margin-top: 24px;
    }
    button {
      padding: 8px 24px;
      border: 2px solid #fff;
      font-size: 12px;
      line-height: 16px;
      width: 120px;
      margin-top: 24px;
    }
  }
`;

const FixedLogo = styled.div`
  position: fixed;
  z-index: 200;
  transition: opacity 0.5s ease-in-out;
  opacity: ${props => (props.scrollProgress >= props.windowHeight ? '1' : '0')};
  height: ${props => props.windowHeight * 0.1}px;
  margin-left: 36px;
  ${mediaMin.tabletLandscape`
    margin-left: 48px;
    opacity: ${props =>
      props.scrollProgress >= props.windowHeight * 0.6 ? '1' : '0'};
         height: ${props => props.windowHeight * 0.06}px;
  `}
  a {
    height: 100%;
    display: flex;
    align-items: center;
    img {
      height: 50%;
    }
  }
`;

const BlueHeading = ({
  pageTitle,
  headerBG,
  openGallery,
  toggleVideoOverlay
}) => {
  const [windowHeight, setWindowHeight] = useState(0);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [bounced, setBounced] = useState(false);

  const updateScrollProgress = () => {
    const scrollPx =
      document.documentElement.scrollTop || document.scrollingElement.scrollTop;
    setScrollProgress(scrollPx);
  };

  const bounceArrow = () => {
    setBounced(true);
    setTimeout(() => {
      setBounced(false);
    }, 500);
  };

  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
    const interval = setInterval(bounceArrow, 3000);
    window.addEventListener('scroll', updateScrollProgress);
    window.addEventListener('resize', () => {
      setWindowHeight(document.documentElement.clientHeight);
    });

    return () => {
      clearInterval(interval);
      window.removeEventListener('scroll', updateScrollProgress);
      window.removeEventListener('resize', () => {
        setWindowHeight(document.documentElement.clientHeight);
      });
    };
  }, []);

  return (
    <HeadingContainer
      scrollProgress={scrollProgress}
      windowHeight={windowHeight}
      headerBG={headerBG}
    >
      <FixedLogo scrollProgress={scrollProgress} windowHeight={windowHeight}>
        <Link to="/">
          <img src="/images/logo-white.svg" alt="1WSQ Logo" />
        </Link>
      </FixedLogo>
      <LeftColumn
        bounced={bounced}
        scrollProgress={scrollProgress}
        windowHeight={windowHeight}
      >
        <div className="top">
          <Link to="/">
            <img src="/images/logo-text-white.svg" alt="1WSQ Logo" />
          </Link>
          <h2 className="page-title">{pageTitle}</h2>
        </div>
        <div className="bottom">
          {!!openGallery ? (
            <button onClick={() => openGallery(0)}>GALLERY</button>
          ) : (
            ''
          )}
          {!!toggleVideoOverlay ? (
            <button onClick={toggleVideoOverlay}>1WSQ FILM</button>
          ) : (
            ''
          )}
        </div>
        <img className="scroll-down" src="/arrow-down.svg" alt="scroll down" />
      </LeftColumn>
      <OpacityLayer
        scrollProgress={scrollProgress}
        windowHeight={windowHeight}
      />
      <MediaQuery minWidth={1025}>
        <ResponsiveImage
          srcPath={headerBG}
          imgClass="blue-heading-bg"
          showExpand={false}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <ResponsiveImage
          srcPath={`${headerBG}_mobile`}
          imgClass="blue-heading-bg"
          showExpand={false}
        />
      </MediaQuery>
    </HeadingContainer>
  );
};

export default BlueHeading;
