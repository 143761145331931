/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  LeftCol,
  RightCol,
  TextSection,
  ImageSection,
  Spacer,
} from "../utils/grid";
import { ResponsiveImage } from "../utils";

const BuildingDesktop = ({ openGallery }) => {
  const [activeSection, setActiveSection] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  const sectionRef1 = useRef(null);
  const sectionRef2 = useRef(null);
  const sectionRef3 = useRef(null);
  const refArray = [sectionRef1, sectionRef2, sectionRef3];

  const handleScroll = () => {
    const transitionPoint = windowHeight * 0.1 + 80 + window.scrollY;
    refArray.forEach((ref, idx) => {
      const top = ref.current.offsetTop;
      const bottom = ref.current.offsetTop + ref.current.offsetHeight;
      if (idx === 0 && transitionPoint < top) {
        setActiveSection(0);
      } else if (
        activeSection === 0 &&
        idx === 0 &&
        transitionPoint >= bottom
      ) {
        setActiveSection(1);
      } else if (transitionPoint >= top && transitionPoint < bottom) {
        setActiveSection(idx + 1);
      }
    });
  };

  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
    window.addEventListener("resize", () => {
      setWindowHeight(document.documentElement.clientHeight);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setWindowHeight(document.documentElement.clientHeight);
      });
    };
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  });

  return (
    <Row>
      <LeftCol>
        <Spacer />
        <TextSection
          windowHeight={windowHeight}
          activeSection={activeSection}
          visible={activeSection <= 1}
        >
          <h5 className="left-col-header">THE BUILDING</h5>
          <p className="left-col-body">
            Introducing 1 Willoughby Square, Brooklyn’s first ground-up office
            tower in a generation. 1WSQ opens directly to the 1-acre Willoughby
            Square Park and climbs 34 stories above Brooklyn to offer
            unobstructed views of New York’s skylines and waterways.
          </p>
        </TextSection>
        <TextSection
          windowHeight={windowHeight}
          activeSection={activeSection}
          visible={activeSection === 2}
        >
          <h5 className="left-col-header">THE OFFICES</h5>
          <p className="left-col-body">
            The 14,500-square-foot floorplates at 1WSQ feature impressive
            column-free lease spans and 14-foot slab heights. Interiors and
            landscaped terraces overlook the new Willoughby Square Park and
            reveal 360-degree vistas from New York Harbor to Prospect Park.
            Multilevel occupants will experience connected floors with 28-foot
            clearances.
          </p>
        </TextSection>
        <TextSection
          windowHeight={windowHeight}
          activeSection={activeSection}
          visible={activeSection === 3}
        >
          <h5 className="left-col-header">THE ARCHITECT</h5>
          <p className="left-col-body">
            FXCollaborative partner Dan Kaplan conceived 1WSQ as an industrial
            loft for the 21st century, combining the exposed structure and
            daylighting of classic New York buildings with the energy-efficient
            systems and interior-exterior integration that today’s businesses
            demand. Kaplan’s design is so successful that FXCollaborative is
            departing Manhattan to reestablish its headquarters on three floors
            of 1WSQ.
          </p>
        </TextSection>
      </LeftCol>
      <RightCol>
        <Spacer />
        <ImageSection ref={sectionRef1}>
          <ResponsiveImage
            srcPath="/images/building/building1"
            srcSet=""
            imgAlt="Rendering of One Wiloughby Square Exterior with east river in background"
            onClickProp={() => openGallery(0)}
            showExpand
          />
          <ResponsiveImage
            srcPath="/images/building/building2"
            srcSet=""
            imgAlt="Close up rendering of One Wiloughby Square Exterior with park"
            onClickProp={() => openGallery(1)}
            showExpand
          />

          <ResponsiveImage
            srcPath="/images/building/building3"
            srcSet=""
            imgAlt="Aerial rendering of One Wiloughby Square Exterior"
            onClickProp={() => openGallery(2)}
            showExpand
          />
          <ResponsiveImage
            srcPath="/images/building/building4"
            srcSet=""
            imgAlt="Rendering of One Wiloughby Square Lobby"
            onClickProp={() => openGallery(3)}
            showExpand
          />
          <ResponsiveImage
            srcPath="/images/building/lobby"
            srcSet=""
            imgAlt="Rendering of One Wiloughby Square Lobby"
            onClickProp={() => openGallery(4)}
            showExpand
          />
        </ImageSection>
        <ImageSection ref={sectionRef2}>
          <ResponsiveImage
            srcPath="/images/building/building5"
            srcSet=""
            imgAlt="Rendering of multilevel office space"
            onClickProp={() => openGallery(5)}
            showExpand
          />

          <ResponsiveImage
            srcPath="/images/building/building6"
            srcSet=""
            imgAlt="Rendering of sunset view over the east river"
            onClickProp={() => openGallery(6)}
            showExpand
          />
          <ResponsiveImage
            srcPath="/images/building/building7"
            imgAlt="Rendering of open office space"
            onClickProp={() => openGallery(7)}
            showExpand
          />

          <ResponsiveImage
            srcPath="/images/building/building8"
            imgAlt="Rendering of lounge area with patio at dusk"
            onClickProp={() => openGallery(8)}
            showExpand
          />

          <ResponsiveImage
            srcPath="/images/building/building9"
            imgAlt="Rendering of rooftop view"
            onClickProp={() => openGallery(9)}
            showExpand
          />
        </ImageSection>
        <ImageSection ref={sectionRef3}>
          <ResponsiveImage
            srcPath="/images/building/building10"
            imgAlt="Photo of Dan Kaplan"
          />
          <ResponsiveImage
            srcPath="/images/building/building11"
            imgAlt="Photo of Blue Brick Detail"
          />
        </ImageSection>
      </RightCol>
    </Row>
  );
};

export default BuildingDesktop;
