import styled from 'styled-components';
import { mediaMin } from '../../stylesheets/MediaQueries';

const Row = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin-top: 100vh;
  flex-direction: column;
  padding: 72px 36px;
  ${mediaMin.tabletLandscape`

  padding: 80px 48px;
    flex-direction: row;
    margin-top: 70vh;
  `}
`;

const BuildingRow = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin-top: 100vh;
  flex-direction: column;
  padding: 72px 0 0 36px;
  ${mediaMin.tabletLandscape`

  padding: 80px 48px;
    flex-direction: row;
    margin-top: 70vh;
  `}
`;

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
  width: 25%;
  padding-right: 60px;
  `}
`;

const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
  width: 75%;

  `}
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  transition: opacity 0.25s ease-in-out;
  ${mediaMin.tabletLandscape`
  opacity: ${props => (props.visible ? '1' : '0')};
  position: ${props => (props.activeSection > 0 ? 'fixed' : 'relative')};
  ${props => {
    if (props.activeSection > 0) {
      const top = `top: ${props.windowHeight * 0.1 + 80}px;`;
      return `width: calc(25vw - 44px); ${top}`;
    }
    return '';
  }}
  `}
  h5 {
    font-family: 'BrownStd Bold';
    height: 18px;
    margin-bottom: 24px;
    color: #000;
    font-size: 18px;
    margin-bottom: 5%;
    letter-spacing: 1px;
    line-height: 18px;
    margin-bottom: 5%;
  }
  p {
    font-family: 'BrownStd Light';
    color: #000;
    font-size: 16px;
    margin-bottom: 5%;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
`;

const MobileTextSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  transition: opacity 0.25s ease-in-out;
  margin-bottom: 24px;
  padding-right: ${props => (props.paddingRight ? '36px' : '0')};
  h5 {
    font-family: 'BrownStd Bold';
    height: 18px;
    margin-bottom: 24px;
    color: #000;
    font-size: 18px;
    margin-bottom: 5%;
    letter-spacing: 1px;
    line-height: 18px;
    margin-bottom: 5%;
  }
  h6 {
    font-family: 'BrownStd Light';
    color: #000;
    font-size: 24px;
    letter-spacing: 0.4px;
    line-height: 24px;
    margin: 8px 0 0 0;
    font-weight: 300;
  }
  p {
    font-family: 'BrownStd Light';
    color: #000;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
  a {
    color: #000;
    &:visited,
    &:hover {
      color: #000;
    }
  }
`;

const ImageSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .responsive-image-container {
    margin-bottom: 80px;
  }
`;

const Spacer = styled.div`
  height: 40px;
  ${mediaMin.tabletLandscape`
    height: 80px;
  `}
`;

const HorizontalSlider = styled.div`
  display: flex;
  flex-direction: row;
  height: 30vh;
  margin-bottom: 24px;
  overflow-x: scroll;
  overflow-y: hidden;
  .responsive-image-container {
    margin-right: 4vw;
    max-width: unset;
    .repsonsive-image {
      height: 100%;
      width: 100%;
    }
  }
`;

const ButtonRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  margin-bottom: 10%;
  justify-content: space-between;
  ${mediaMin.tabletLandscape`
    justify-content: flex-start;
  `}
`;

const MapButton = styled.button`
  position: relative;
  font-family: 'BrownStd Regular';
  opacity: ${props => (props.active ? '1' : '0.5')};
  font-size: 12px;
  letter-spacing: 0.6px;
  line-height: 16px;
  padding-bottom: 0.5%;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: color ease 0.3s;
  height: fit-content;
  ${mediaMin.tabletLandscape`
    margin-right: 24px;
  `}

  &::before,
  &::after {
    content: '';
    position: absolute;
    opacity: ${props => (props.active ? '1' : '0')};
    background-color: #000;
    z-index: 1;
    height: 2px;
  }

  &::before {
    width: 0%;
    left: 0;
    bottom: 0;
    transition: width ease 0.4s;
  }

  &::after {
    width: 100%;
    left: 0;
    bottom: 0;
    transition: all ease 0.6s;
  }

  &:hover {
    &::before {
      width: 100%;
    }
    &::after {
      left: 100%;
      width: 0%;
      transition: all ease 0.2s;
    }
  }
`;

const BodyContainer = styled.div`
  position: relative;
  height: 148px;
`;

const BodyText = styled.p`
  position: absolute;
  color: #000;
  font-size: 16px;
  font-family: 'BrownStd Light';
  margin-bottom: 5%;
  letter-spacing: 0.4px;
  line-height: 24px;
  opacity: ${props => (props.active ? '1' : '0')};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  transition: all 0.5s ease-in-out;
`;

export {
  Row,
  BuildingRow,
  LeftCol,
  RightCol,
  TextSection,
  MobileTextSection,
  ImageSection,
  Spacer,
  HorizontalSlider,
  ButtonRow,
  MapButton,
  BodyContainer,
  BodyText
};
