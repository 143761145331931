/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { mediaMin } from '../../stylesheets/MediaQueries';

const ResponsiveImageContainer = styled.div`
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  display: inline;
  position: relative;
  .responsive-image {
    max-height: 100%;
    object-fit: contain;
    ${mediaMin.tabletLandscape`
    max-width: 100%;
  `}
  }
`;

const DesktopButton = styled.button`
  display: none;
  ${mediaMin.tabletLandscape`
display: inline-block;
z-index: 100;
position: absolute;
left: 24px;
bottom: 24px;
opacity: ${props => (props.expandVisible ? '1' : '0')};
visibility: ${props => (props.expandVisible ? 'visible' : 'hidden')};
transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
color: #fff;
background-color: rgba(0, 11, 80, 0.8);
border: 2px solid #fff;
padding: 8px 24px;
font-family: 'BrownStd Regular';
font-size: 14px;
line-height: 18px;
img {
  margin-left: 8px;
}
`}
`;

const MobileButton = styled.button`
  z-index: 100;
  position: absolute;
  right: 16px;
  bottom: 16px;
  opacity: ${props => (props.expandVisible ? '1' : '0')};
  visibility: ${props => (props.expandVisible ? 'visible' : 'hidden')};
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  ${mediaMin.tabletLandscape`
  display: none;
`}
`;

const ResponsiveImage = props => {
  const [expandVisible, setExpandVisible] = useState(false);
  const {
    srcPath,
    imgClass,
    onClickProp,
    ariaHidden,
    showExpand,
    imgAlt
  } = props;

  useEffect(() => {
    if (window.innerWidth < 1024 && showExpand) {
      setExpandVisible(true);
    }
  }, []);

  return (
    <ResponsiveImageContainer className="responsive-image-container">
      <img
        onMouseEnter={() => {
          if (showExpand) setExpandVisible(true);
        }}
        onMouseLeave={() => {
          setExpandVisible(false);
        }}
        src={`${srcPath}.jpg`}
        srcSet={`${srcPath}_400.jpg 400w, ${srcPath}_600.jpg 600w, ${srcPath}_800.jpg 800w, ${srcPath}_1100.jpg 1100w, ${srcPath}_1500.jpg 1500w, ${srcPath}_2000.jpg 2000w, ${srcPath}_2500.jpg 2500w`}
        alt={imgAlt}
        className={`responsive-image ${imgClass}`}
        onClick={onClickProp}
        aria-hidden={ariaHidden === undefined ? false : ariaHidden}
      />
      <DesktopButton
        expandVisible={expandVisible}
        onClick={onClickProp}
        onMouseEnter={() => {
          setExpandVisible(true);
        }}
        onMouseLeave={() => {
          setExpandVisible(false);
        }}
      >
        <span>VIEW IMAGES</span>
        <img src="/popout.svg" alt="popout" aria-hidden />
      </DesktopButton>
      <MobileButton
        expandVisible={expandVisible}
        onClick={onClickProp}
        onMouseEnter={() => {
          if (showExpand) setExpandVisible(true);
        }}
        onMouseLeave={() => {
          setExpandVisible(false);
        }}
      >
        <img src="/expand.svg" alt="popout" aria-hidden />
      </MobileButton>
    </ResponsiveImageContainer>
  );
};

export default ResponsiveImage;
