import React from "react";
import styled from "styled-components";

import { ResponsiveImage } from "../utils";
import {
  BuildingRow,
  MobileTextSection,
  Spacer,
  HorizontalSlider,
} from "../utils/grid";

const SliderSpacer = styled.div`
  min-width: 36px;
`;

const BuildingMobile = ({ openGallery }) => {
  return (
    <BuildingRow>
      <Spacer />
      <MobileTextSection paddingRight>
        <h5>THE BUILDING</h5>
      </MobileTextSection>
      <HorizontalSlider>
        <ResponsiveImage
          srcPath="/images/building/building1_square"
          imgAlt="Rendering of One Wiloughby Square Exterior with east river in background"
          onClickProp={() => openGallery(0)}
          showExpand
        />

        <ResponsiveImage
          srcPath="/images/building/building2_square"
          imgAlt="Close up rendering of One Wiloughby Square Exterior with park"
          onClickProp={() => openGallery(1)}
          showExpand
        />

        <ResponsiveImage
          srcPath="/images/building/building3_square"
          imgAlt="Aerial rendering of One Wiloughby Square Exterior"
          onClickProp={() => openGallery(2)}
          showExpand
        />

        <ResponsiveImage
          srcPath="/images/building/building4_square"
          imgAlt="Rendering of One Wiloughby Square Lobby"
          onClickProp={() => openGallery(3)}
          showExpand
        />

        <ResponsiveImage
          srcPath="/images/building/lobby_square"
          srcSet=""
          imgAlt="Rendering of One Wiloughby Square Lobby"
          onClickProp={() => openGallery(4)}
          showExpand
        />

        <SliderSpacer />
      </HorizontalSlider>
      <MobileTextSection paddingRight>
        <p>
          Introducing 1 Willoughby Square, Brooklyn’s first ground-up office
          tower in a generation. 1WSQ opens directly to the 1-acre Willoughby
          Square Park and climbs 34 stories above Brooklyn to offer unobstructed
          views of New York’s skylines and waterways.
        </p>
      </MobileTextSection>
      <Spacer />
      <MobileTextSection paddingRight>
        <h5>THE OFFICES</h5>
      </MobileTextSection>
      <HorizontalSlider>
        <ResponsiveImage
          srcPath="/images/building/building5_square"
          imgAlt="Rendering of multilevel office space"
          onClickProp={() => openGallery(5)}
          showExpand
        />

        <ResponsiveImage
          srcPath="/images/building/building6_square"
          imgAlt="Rendering of sunset view over the east river"
          onClickProp={() => openGallery(6)}
          showExpand
        />

        <ResponsiveImage
          srcPath="/images/building/building7_square"
          imgAlt="Rendering of open office space"
          onClickProp={() => openGallery(7)}
          showExpand
        />

        <ResponsiveImage
          srcPath="/images/building/building8_square"
          imgAlt="Rendering of lounge area with patio at dusk"
          onClickProp={() => openGallery(8)}
          showExpand
        />
        <ResponsiveImage
          srcPath="/images/building/building9_square"
          imgAlt="Rendering of rooftop view"
          onClickProp={() => openGallery(9)}
          showExpand
        />

        <SliderSpacer />
      </HorizontalSlider>
      <MobileTextSection paddingRight>
        <p>
          The 14,500-square-foot floorplates at 1WSQ feature impressive
          column-free lease spans and 14-foot slab heights. Interiors and
          landscaped terraces overlook the new Willoughby Square Park and reveal
          360-degree vistas from New York Harbor to Prospect Park. Multilevel
          occupants will experience connected floors with 28-foot clearances.
        </p>
      </MobileTextSection>
      <Spacer />
      <MobileTextSection paddingRight>
        <h5>THE ARCHITECT</h5>
      </MobileTextSection>
      <HorizontalSlider>
        <ResponsiveImage
          srcPath="/images/building/building10_square"
          imgAlt="Photo of Dan Kaplan"
        />
        <ResponsiveImage
          srcPath="/images/building/building11_square"
          imgAlt="Photo of Dan Kaplan"
        />
        <SliderSpacer />
      </HorizontalSlider>
      <MobileTextSection paddingRight>
        <p>
          FXCollaborative partner Dan Kaplan conceived 1WSQ as an industrial
          loft for the 21st century, combining the exposed structure and
          daylighting of classic New York buildings with the energy-efficient
          systems and interior-exterior integration that today’s businesses
          demand. Kaplan’s design is so successful that FXCollaborative is
          departing Manhattan to reestablish its headquarters on three floors of
          1WSQ.
        </p>
      </MobileTextSection>
      <Spacer />
    </BuildingRow>
  );
};

export default BuildingMobile;
