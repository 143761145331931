import React from 'react';
import NeighborhoodMapContainer from './neighborhood-map-container';

import {
  Row,
  MobileTextSection,
  Spacer,
  ButtonRow,
  MapButton,
  BodyContainer,
  BodyText
} from '../utils/grid';

const NeighborhoodMobile = ({
  currentMap,
  changeCurrentMap,
  zoomIn,
  zoomOut,
  mapZoomed
}) => {
  return (
    <Row>
      <MobileTextSection>
        <Spacer />
        <h5>THE NEIGHBORHOOD</h5>
        <ButtonRow>
          <MapButton
            active={currentMap === 0}
            onClick={() => changeCurrentMap(0)}
          >
            TRANSIT
          </MapButton>
          <MapButton
            active={currentMap === 1}
            onClick={() => changeCurrentMap(1)}
          >
            DINING
          </MapButton>
          <MapButton
            active={currentMap === 2}
            onClick={() => changeCurrentMap(2)}
          >
            AMENITIES
          </MapButton>
        </ButtonRow>
        <BodyContainer>
          <BodyText active={currentMap === 0}>
            Downtown Brooklyn is New York’s third CBD, boasting an array of
            services while maintaining citywide and regional connections via
            LIRR and subway lines.
          </BodyText>
          <BodyText active={currentMap === 1}>
            In a borough known as a foodie paradise, 1 Willoughby Square is
            located next to culinary destinations that range from Grand Army and
            Yaso Tangbao to DeKalb Market Hall.
          </BodyText>
          <BodyText active={currentMap === 2}>
            Downtown Brooklyn stokes the imagination of its famous creative
            class, thanks to definitive arts programming at BAM, Barclays
            Center, BRIC, and Mark Morris.
          </BodyText>
        </BodyContainer>
      </MobileTextSection>
      <NeighborhoodMapContainer
        currentMap={currentMap}
        zoomIn={zoomIn}
        zoomOut={zoomOut}
        mapZoomed={mapZoomed}
      />
    </Row>
  );
};

export default NeighborhoodMobile;
