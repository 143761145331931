const overlayHighInfo = {
  title: 'HIGH RISE',
  floors: 'Floors 15-34',
  area: '14,500 SF',
  ceilingHeight: '14 FEET',
  headCountTwo: '36',
  headCountThree: '91',
  floorplanOne: '/images/floorplans/high-rise-shell.svg',
  floorplanOneAlt: 'Unfurnished floor plan of office in high rise section',
  floorplanTwo: '/images/floorplans/high-rise-low-density.svg',
  floorplanTwoAlt:
    'low denisty furnished floor plan of office in high rise section',
  floorplanThree: '/images/floorplans/high-rise-high-density.svg',
  floorplanThreeAlt:
    'high density furnished floor plan of office in high rise section'
};
const overlayLowInfo = {
  title: 'LOW RISE',
  floors: 'Floors 7-14',
  area: '14,500 SF',
  ceilingHeight: '14 FEET',
  headCountOne: '78',
  headCountTwo: '68',
  headCountThree: '78',
  headCountFour: '61',
  floorplanOne: '/images/floorplans/low-rise-1.svg',
  floorplanOneAlt: 'Unfurnished floor plan of office in low rise section',
  floorplanTwo: '/images/floorplans/low-rise-2.svg',
  floorplanTwoAlt:
    'low denisty furnished floor plan of office in low rise section',
  floorplanThree: '/images/floorplans/low-rise-3.svg',
  floorplanThreeAlt:
    'high density furnished floor plan of office in low rise section',
  floorplanFour: '/images/floorplans/low-rise-3A.svg',
  floorplanFourAlt:
    'high density furnished floor plan of office in low rise section'
};
const overlaySuperInfo = {
  title: 'SUPERFLOOR',
  floors: 'Floors 7, 15, 25, 34',
  area: '12,081 SF',
  ceilingHeight: '16 FEET',
  // lowOccupancy: '36',
  // highOccupancy: '91',
  floorplanOne: '/images/floorplans/superfloor.svg',
  floorplanOneAlt: 'Unfurnished floor plan of office in superfloor section'
};

export { overlayLowInfo, overlayHighInfo, overlaySuperInfo };
