import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import BottomScrollListener from 'react-bottom-scroll-listener';

import Footer from '../footer';
import { mediaMin } from '../../stylesheets/MediaQueries';

const AccessibilityPage = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 72px 36px;
  flex-direction: column;
  height: 100vh;
  ${mediaMin.tabletLandscape`
  flex-direction: row;
  height: calc(80vh - 44px);
  padding: 80px 48px;
  `}
`;

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
  height: 100%;
  position: fixed;
    width: 25%;
  `}
  a {
    img {
      width: 100%;
      ${mediaMin.tabletLandscape`
        width: unset;
      `}
    }
  }
  h5 {
    margin-top: 48px;
    font-family: 'BrownStd Bold';
    color: #000;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 18px;
  }
`;

const RightCol = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 48px;
  ${mediaMin.tabletLandscape`
  margin-top: 121px;
    padding: 0 25%;
  `}
  p {
    font-family: 'BrownStd Light';
    color: #000;
    font-size: 16px;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
`;

const TextSection = styled.div``;

const Accessibility = () => {
  const [footerShow, setFooterShow] = useState(true);
  return (
    <>
      <AccessibilityPage>
        <LeftCol>
          <TextSection>
            <Link to="/">
              <img src="images/logo-text-black.svg" alt="1WSQ Logo" />
            </Link>
            <h5>
              ACCESSIBILITY
              <br />
              STATEMENT
            </h5>
          </TextSection>
        </LeftCol>
        <RightCol>
          <TextSection>
            <p>
              1WSQ is committed to providing a website that is accessible to the
              widest possible audience, regardless of technology or ability. We
              aim to comply with all applicable standards. If you experience any
              difficulty in accessing any part of this website, please contact
              us by emailing info@jembrealty.com or calling us at (212)608-5100.
            </p>
          </TextSection>
        </RightCol>
      </AccessibilityPage>
      <Footer footerShow={footerShow} />
      <BottomScrollListener
        offset="10"
        onBottom={() => {
          setFooterShow(true);
        }}
      />
    </>
  );
};

export default Accessibility;
