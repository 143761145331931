import React, { useState, useEffect, useRef } from 'react';

import {
  Row,
  LeftCol,
  RightCol,
  TextSection,
  Spacer,
  ButtonRow,
  MapButton,
  BodyContainer,
  BodyText
} from '../utils/grid';
import NeighborhoodMapContainer from './neighborhood-map-container';

const NeighborhoodDesktop = ({
  currentMap,
  changeCurrentMap,
  zoomIn,
  zoomOut,
  mapZoomed
}) => {
  const [activeSection, setActiveSection] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  const sectionRef1 = useRef(null);
  const refArray = [sectionRef1];

  const handleScroll = () => {
    const transitionPoint = windowHeight * 0.1 + 80 + window.scrollY;
    refArray.forEach((ref, idx) => {
      const top = ref.current.offsetTop;
      const bottom = ref.current.offsetTop + ref.current.offsetHeight;
      if (idx === 0 && transitionPoint < top) {
        setActiveSection(0);
      } else if (
        activeSection === 0 &&
        idx === 0 &&
        transitionPoint >= bottom
      ) {
        setActiveSection(1);
      } else if (transitionPoint >= top && transitionPoint < bottom) {
        setActiveSection(idx + 1);
      }
    });
  };

  useEffect(() => {
    setWindowHeight(document.documentElement.clientHeight);
    window.addEventListener('resize', () => {
      setWindowHeight(document.documentElement.clientHeight);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setWindowHeight(document.documentElement.clientHeight);
      });
    };
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <Row>
      <LeftCol>
        <Spacer />
        <TextSection
          windowHeight={windowHeight}
          activeSection={activeSection}
          visible={activeSection <= 1}
        >
          <h5>THE NEIGHBORHOOD</h5>
          <ButtonRow>
            <MapButton
              active={currentMap === 0}
              onClick={() => changeCurrentMap(0)}
            >
              TRANSIT
            </MapButton>
            <MapButton
              active={currentMap === 1}
              onClick={() => changeCurrentMap(1)}
            >
              DINING
            </MapButton>
            <MapButton
              active={currentMap === 2}
              onClick={() => changeCurrentMap(2)}
            >
              AMENITIES
            </MapButton>
          </ButtonRow>
          <BodyContainer>
            <BodyText
              active={currentMap === 0}
              aria-hidden={currentMap === 0 ? false : true}
            >
              Downtown Brooklyn is New York’s third CBD, boasting an array of
              services while maintaining citywide and regional connections via
              LIRR and subway lines.
            </BodyText>
            <BodyText
              active={currentMap === 1}
              aria-hidden={currentMap === 1 ? false : true}
            >
              In a borough known as a foodie paradise, 1 Willoughby Square is
              located next to culinary destinations that range from Grand Army
              and Yaso Tangbao to DeKalb Market Hall.
            </BodyText>
            <BodyText
              active={currentMap === 2}
              aria-hidden={currentMap === 2 ? false : true}
            >
              Downtown Brooklyn stokes the imagination of its famous creative
              class, thanks to definitive arts programming at BAM, Barclays
              Center, BRIC, and Mark Morris.
            </BodyText>
          </BodyContainer>
        </TextSection>
      </LeftCol>
      <RightCol ref={sectionRef1}>
        <Spacer />
        <NeighborhoodMapContainer
          currentMap={currentMap}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          mapZoomed={mapZoomed}
        />
      </RightCol>
    </Row>
  );
};

export default NeighborhoodDesktop;
