import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import BackgroundVideo from "./background-video";
import FilmButton from "./film-button";
import VideoOverlay from "../utils/video-overlay";
import { mediaMin } from "../../stylesheets/MediaQueries";

const HomeContainer = styled.div`
  height: 100%;
  width: 100%;
  text-decoration: none;
  border-bottom: none;
  z-index: 1;
  font-family: "BrownStd Thin";
  position: fixed;
`;

const NavRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height 100%;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 72px 36px;
  flex-direction: column;
  ${mediaMin.tabletLandscape`
    padding: 80px 48px;
    flex-direction: row;
  `}
`;

const LeftColumn = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
  top: 5%;
  transform: translate(0, 0);
  .home-logo {
    img {
      width: 100%;
    }
  }
  ${mediaMin.tablet`
    width: 50%;
  `}
  ${mediaMin.tabletLandscape`
  width: auto;
  top: 0;
  height: 100%;
  bottom: unset;
  left: ;
  `}
`;

const RightColumn = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  ${mediaMin.tabletLandscape`
    justify-content: flex-start;
    flex-direction: column;
    width: fit-content;
    height: 100%;

  `}
`;

const MainNav = styled.nav`
  display: flex;
  flex-direction: column;
  a {
    color: #fff;
    margin-top: 8px;
    font-size: 32px;
    line-height: 32px;
    ${mediaMin.tablet`
      font-size: 40px;
      line-height: 40px;
    `}
    ${mediaMin.tabletLandscape`
      font-size: 56px;
      line-height: 56px;
      margin-top: 16px;
      transition: transform .2s ease-in-out;
      `}
    &:hover {
      color: #fff;
      transform: translateX(4px);
    }
    &:visited {
      color: #fff;
    }
  }
`;

const SmallNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.flexEnd ? "flex-end" : "space-between")};
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 18px;
  font-family: "BrownStd Light";
  a {
    margin-top: 16px;
    color: #fff;
    transition: transform 0.2s ease-in-out;
    &:hover {
      color: #fff;
      transform: translateX(-4px);
    }
    &:visited {
      color: #fff;
    }
    &:first-child {
      margin-top: 0;
    }
  }
  button {
    ${mediaMin.tabletLandscape`
        margin: 0 0 24px 0;
    `}
    &:first-child {
      margin-top: 0;
    }
  }
`;

const Home = () => {
  const [videoState, setVideoState] = useState(false);
  const toggleVideoOverlay = () => {
    setVideoState((prev) => !prev);
  };
  return (
    <>
      <HomeContainer>
        <NavRow>
          <LeftColumn left>
            <Link
              to="/"
              className="home-logo"
              aria-label="one willoughby square"
            >
              <img src="/images/logo-text-white.svg" alt="1WSQ Logo" />
            </Link>
            <MainNav>
              <Link to="/building">BUILDING</Link>
              <Link to="/availability">AVAILABILITY</Link>
              <Link to="/views">VIEWS</Link>
              <Link to="/neighborhood">NEIGHBORHOOD</Link>
              <Link to="/incentives">INCENTIVES</Link>
            </MainNav>
          </LeftColumn>
          <RightColumn right>
            <SmallNav flexEnd>
              <FilmButton
                onClick={toggleVideoOverlay}
                aria-label="play 1wsq film"
              />
              {/* <a
              href="http://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/instagram.svg" alt="Link to Instagram" />
            </a> */}
            </SmallNav>
            <SmallNav>
              <Link to="/contact">
                <div className="small-nav-link">CONTACT</div>
              </Link>
              <Link to="/team">
                <div className="small-nav-link">TEAM</div>
              </Link>
              <Link to="/press">
                <div className="small-nav-link">PRESS</div>
              </Link>
              <Link to="/legal">
                <div className="small-nav-link">LEGAL</div>
              </Link>
            </SmallNav>
          </RightColumn>
        </NavRow>
        <BackgroundVideo />
      </HomeContainer>
      <VideoOverlay
        videoOverlayState={videoState}
        toggleVideo={toggleVideoOverlay}
      />
    </>
  );
};

export default Home;
