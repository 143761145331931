/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Selector from './selector';
import Legend from './legend';

const LeftCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  width: 33%;
  position: fixed;

  .heading-container {
    .heading {
      color: #fff;
      font-size: 100px;
      font-family: 'BrownStd Thin';
      margin-bottom: 2.5%;
      margin-left: -5px;
    }
    .sub-heading {
      font-family: 'BrownStd Light';
      color: #fff;
      font-size: 18px;
      letter-spacing: 1px;
      line-height: 18px;
    }
  }
`;

const CenterCol = styled.div`
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 100%;
  .building-svg {
    height: 100%;
  }
`;

const AvailabilityDesktop = ({
  hoverState,
  updateMouseOver,
  openOverlayHigh,
  openOverlayLow,
  openOverlaySuper
}) => {
  const [DOMContentLoaded, setDOMContentLoaded] = useState(false);

  const setUpSVGTransitions = () => {
    setDOMContentLoaded(true);
    //sets up animations for building svg

    const svgObject = document.getElementById('building-svg');
    const svgObjectDocument = svgObject.contentDocument;
    const highRise = svgObjectDocument.getElementById('Highrise_Highlight');
    const lowRise = svgObjectDocument.getElementById('Lowrise_Highlight');
    const superFloor = svgObjectDocument.getElementById('Superfloor_Highlight');
    highRise.onmouseover = () => {
      svgObjectDocument.getElementById(
        'Highrise_Highlight'
      ).style.opacity = 0.3;
      updateMouseOver('HIGH');
    };
    highRise.onmouseout = () => {
      svgObjectDocument.getElementById('Highrise_Highlight').style.opacity = 0;
      updateMouseOver('');
    };
    lowRise.onmouseover = () => {
      svgObjectDocument.getElementById('Lowrise_Highlight').style.opacity = 1;
      updateMouseOver('LOW');
    };
    lowRise.onmouseout = () => {
      svgObjectDocument.getElementById('Lowrise_Highlight').style.opacity = 0;
      updateMouseOver('');
    };
    superFloor.onmouseover = () => {
      svgObjectDocument.getElementById(
        'Superfloor_Highlight'
      ).style.opacity = 1;
      updateMouseOver('LOW');
    };
    superFloor.onmouseout = () => {
      svgObjectDocument.getElementById(
        'Superfloor_Highlight'
      ).style.opacity = 0;
      updateMouseOver('');
    };
    highRise.onclick = () => {
      openOverlayHigh();
    };
    lowRise.onclick = () => {
      openOverlayLow();
    };
    superFloor.onclick = () => {
      openOverlaySuper();
    };
  };

  const fadeBuildingSVG = () => {
    let svgObject = document.getElementById('building-svg');
    if (DOMContentLoaded) {
      let svgObjectDocument = svgObject.contentDocument;
      if (
        svgObjectDocument.getElementById('Highrise_Highlight') &&
        svgObjectDocument.getElementById('Lowrise_Highlight') &&
        svgObjectDocument.getElementById('Superfloor_Highlight')
      ) {
        if (hoverState === 'HIGH') {
          svgObjectDocument.getElementById(
            'Highrise_Highlight'
          ).style.opacity = 1;
        } else if (hoverState === 'LOW') {
          svgObjectDocument.getElementById(
            'Lowrise_Highlight'
          ).style.opacity = 1;
        } else if (hoverState === 'SUPER') {
          svgObjectDocument.getElementById(
            'Superfloor_Highlight'
          ).style.opacity = 1;
        } else {
          svgObjectDocument.getElementById(
            'Highrise_Highlight'
          ).style.opacity = 0;
          svgObjectDocument.getElementById(
            'Lowrise_Highlight'
          ).style.opacity = 0;
          svgObjectDocument.getElementById(
            'Superfloor_Highlight'
          ).style.opacity = 0;
        }
      }
    }
  };

  useEffect(fadeBuildingSVG, [hoverState]);

  useEffect(() => {
    setTimeout(setUpSVGTransitions, 1000);
  }, []);

  return (
    <>
      <LeftCol>
        <div className="heading-container">
          <Link to="/" className="heading" aria-label="one willoughby square">
            <img src="/images/logo-text-white.svg" alt="1WSQ LOGO" />
          </Link>
        </div>
        <Selector
          hoverState={hoverState}
          updateMouseOver={updateMouseOver}
          openOverlayHigh={openOverlayHigh}
          openOverlayLow={openOverlayLow}
          openOverlaySuper={openOverlaySuper}
        />
        <Legend />
      </LeftCol>
      <CenterCol>
        <object
          className="building-svg"
          id="building-svg"
          data="/images/availability/building.svg"
          type="image/svg+xml"
          alt="1WSQ Building Visualization"
        >
          BUILDING
        </object>
      </CenterCol>
    </>
  );
};

export default AvailabilityDesktop;
