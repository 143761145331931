import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Selector from './selector';
import Legend from './legend';
import { mediaMin } from '../../stylesheets/MediaQueries';

const MobileCol = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin: 0;
  min-height: 100vh;
  a {
    margin-bottom: 80px;
    width: 100%;
    ${mediaMin.tablet`
      width: 50%;
    `}
    img {
      width: 100%;
    }
  }
`;

const InnerMobileCol = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20%;
  height: 60vh;
`;

const LeftCol = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  padding-right: 8px;
`;

const CenterCol = styled.div`
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding-left: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const AvailabilityMobile = ({
  hoverState,
  updateMouseOver,
  openOverlayHigh,
  openOverlayLow,
  openOverlaySuper
}) => {
  return (
    <MobileCol>
      <Link to="/" className="heading" aria-label="one willoughby square">
        <img src="/images/logo-text-white.svg" alt="1WSQ LOGO" />
      </Link>
      <InnerMobileCol>
        <LeftCol>
          <Selector
            hoverState={hoverState}
            updateMouseOver={updateMouseOver}
            openOverlayHigh={openOverlayHigh}
            openOverlayLow={openOverlayLow}
            openOverlaySuper={openOverlaySuper}
          />
          <Legend />
        </LeftCol>
        <CenterCol>
          <object
            className="building-svg"
            id="building-svg"
            data="/images/availability/building.svg"
            type="image/svg+xml"
            alt="1WSQ Building Visualization"
          >
            BUILDING
          </object>
        </CenterCol>
      </InnerMobileCol>
    </MobileCol>
  );
};

export default AvailabilityMobile;
